import { get, put, post, del } from "aws-amplify/api";

const API = {
  get: async (apiName, path, options) => {
    const response = await get({ apiName, path, options }).response;
    return response.body.json();
  },
  post: async (apiName, path, options) => {
    const response = await post({ apiName, path, options }).response;
    return response.body.json();
  },
  put: async (apiName, path, options) => {
    const response = await put({ apiName, path, options }).response;
    return response.body.json();
  },
  del: async (apiName, path, options) => {
    const response = await del({ apiName, path, options }).response;
    return response.body.json();
  },
};

export const login = async (e, apiKey, setMessage, setUserInfo) => {
  e.preventDefault();
  try {
    const loginResponse = await API.get(
      "brains",
      "/auth/clientDashboardLogin",
      {
        credentials: "include",
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );
    if (!loginResponse || loginResponse.status !== 200) {
      console.error(loginResponse);
      setMessage(loginResponse?.info ?? "Not found company");
    }
    if (loginResponse.status === 200) {
      setMessage("");
      const { token } = loginResponse;
      localStorage.setItem("dashboardToken", token);
      sessionStorage.setItem("dashboardToken", token);
      setUserInfo({ ...loginResponse.data, token });
    } else {
      setMessage(loginResponse.info);
    }
  } catch (err) {
    console.log(err);
    setMessage(err.info);
    return err;
  }
};

/**
 * API request to get the list of favorites for the current user.
 * @param {string} apiKey - Api key of company.
 * @param {function} setFavorites - Function to set the favorites.
 * @returns {Promise<void>}
 */
export const getFavorites = async (apiKey, setFavorites) => {
  try {
    const response = await API.get("brains", "/favorite/companyFavorites", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });

    if (response.status !== 200 || !response.data) {
      console.error(response);
      return { error: "Invalid API key" };
    }
    setFavorites(response.data);
  } catch (err) {
    console.log(err);
    return { error: "Invalid API key" };
  }
};

export const getPendingFavorites = async (
  apiKey,
  setFavorites,
  setRejectedFavorites
) => {
  try {
    const response = await API.get(
      "brains",
      "/favorite/clientPendingFavorites",
      {
        credentials: "include",
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );

    if (response.status !== 200 || !response.data) {
      console.error(response);
      return { error: "Invalid API key" };
    }
    setFavorites(response.data.pending);
    setRejectedFavorites(response.data.rejected);
  } catch (err) {
    console.log(err);
    return { error: "Invalid API key" };
  }
};

/**
 * API request to add or edit a favorite from company.
 * @param {string} apiKey - Api key of company.
 * @param {object} favorite - Favorite object to add or edit.
 * @param {function} setErrorMessage - Callback to set error message.
 */
export const addOrEditFavorite = async (apiKey, favorite, setErrorMessage) => {
  try {
    const { _id, ...formObj } = favorite;
    const response = await API.post("brains", "/favorite/addOrEditClient", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
      body: { formObj },
    });

    if (response.status !== 200 || !response.data) {
      console.error("status error", response);
      setErrorMessage("Something went wrong, please try again");
      return;
    }

    return response.data;
  } catch (err) {
    const errorText = JSON.parse(err._response.body).error;
    setErrorMessage(errorText);
    return;
  }
};

/**
 * API request to get the list of visits for company using apiKeyDashboard.
 * @param {string} apiKey - Api key for dashboard of company.
 * @param {function} setVisits - Function to set the visits.
 * @returns {Promise<void>}
 */
export const getVisitsForCompany = async (apiKey, setVisits) => {
  try {
    const response = await API.get("brains", "/visit/external", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });

    if (response.status !== 200 || !response.data) {
      console.error(response);
      return { error: "Invalid dashboard API key" };
    }

    setVisits(response.data);
  } catch (err) {
    console.log(err);
    return { error: "Invalid dashboard API key" };
  }
};

export const getVisitBySearch = async (
  apiKey,
  masterId,
  setVisits,
  setError
) => {
  try {
    const response = await API.post("brains", "/visit/externalByMasterId", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
      body: {
        masterId,
      },
    });

    if (response.status !== 200 || !response.data || !response.data.length) {
      console.error(response);
      setError("No visit found");
      setVisits([]);
      return { error: "Invalid dashboard API key" };
    }
    setError("");
    setVisits(response.data);
  } catch (err) {
    console.log(err);
    return { error: "Invalid dashboard API key" };
  }
};

/**
 * API request to get list of pharmacies.
 * @param {string} token - Jwt token of current user.
 * @returns {Promise<{
 *  _id: string,
 *  __v: string,
 *  pharmacyId: string,
 *  name: string,
 * }[]>}
 */
export const getPharmacies = async (token) => {
  try {
    const response = await API.get("brains", `/dosespot/pharmaciesClient`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return {
      error: err,
      errorMessage: err?.response?.data?.error,
    };
  }
};

/**
 * API request to remove a company from favorites.
 * @param {string} apiKey - Api key of company.
 * @param {string} companyName - Company name.
 * @param {object} favorite - Favorite in which company is to be removed.
 * @param {*} setErrorMessage - Callback to set error message.
 * @returns {Promise<boolean>} - Returns true if the company was removed from favorites, false otherwise.
 */
export const removeCompanyFromFavorite = async (
  apiKey,
  companyName,
  favorite,
  setErrorMessage
) => {
  try {
    const response = await API.post("brains", "/favorite/updateCompanies", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
      body: {
        company: companyName,
        medId: favorite.clientName,
        subtractions: [companyName],
      },
    });

    if (response.status !== 200 || !response.data) {
      console.error(response);
      setErrorMessage("Something went wrong, please try again");
      return false;
    }

    return true;
  } catch (err) {
    console.log(err);
    return { error: "Invalid API key" };
  }
};

export const acceptRejection = async (apiKey, name, setErrorMessage) => {
  try {
    const response = await API.post("brains", "/favorite/deleteRejected", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
      body: {
        clientName: name,
      },
    });

    if (response.status !== 200 || !response.data) {
      console.error(response);
      setErrorMessage("Something went wrong, please try again");
      return;
    }

    return response.data;
  } catch (err) {
    console.log(err);
    setErrorMessage("Something went wrong, please try again");
  }
};
